"use client";

import React from "react";
import Image from "next/image";
import { Box, Typography, useMediaQuery } from "@mui/material";

export const Platform: React.FC = () => {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box display={"flex"} flexDirection="column" gap={6}>
      <Box
        display="flex"
        position="relative"
        flexDirection={{ xs: "column-reverse", lg: "row" }}
        gap={4}
      >
        <Box>
          <Typography variant="h2" mb={1}>
            Emiteo to platforma
          </Typography>
          <Typography variant={isMdUp ? "h2" : "h4"} fontWeight={300}>
            crowdfundingu inwestycyjnego
          </Typography>
        </Box>
        <Box
          sx={{
            position: { lg: "absolute" },
            top: 0,
            alignSelf: "center",
            right: 200,
            zIndex: -1,
          }}
        >
          <Image
            alt="kwiatek"
            width={223}
            height={248}
            src="/images/index/plant.jpg"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        gap={4}
        alignItems="end"
      >
        <Box display="flex" flexDirection="column" gap={4}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="body1c">Co to takiego?</Typography>
            <Typography variant="body2a">
              Crowdfunding inwestycyjny (także equity crowdfunding, w skrócie
              ECF) to uproszczona oferta publiczna. W jej ramach spółka emituje
              akcje, a szeroka społeczność ma możliwość w nie zainwestować.
            </Typography>
            <Typography variant="body2a">
              Dzięki crowdfundingowi firmy pozyskują kapitał, a inwestorzy
              zyskują wpływ na ich dalszy rozwój…
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="body1c">Jakie są z tego korzyści?</Typography>
            <Typography variant="body2a">
              …i perki, czyli ekstra korzyści, przyznawane przy zakupie
              określonych pakietów akcji: limitowane serie produktów, dodatkowe
              usługi czy zniżki.
            </Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="body1c">Dla kogo?</Typography>
          <Typography variant="body2a">
            Zainwestować możesz nawet niewielkie sumy, a akcjonariuszem może
            zostać każda pełnoletnia osoba.
          </Typography>
          <Typography variant="body2a">
            Spółki decydujące się na crowdfunding to ambitne firmy z
            najróżniejszych branż: twórcy aplikacji, producenci rzemieślniczych
            alkoholi, kluby sportowe, studia gamedevowe, sklepy internetowe i
            nie tylko.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
