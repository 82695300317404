"use client";

import Image from "next/image";
import { route } from "@/app/lib/utils/routes";
import { emiteoAddress } from "@/app/lib/utils/commonHelpers";
import {
  SimpleNewsletterForm,
  schema,
} from "@/app/lib/components/commons/SimpleNewsletterForm";
import { FieldError, UseFormRegister } from "react-hook-form";
import yup from "yup";
import {
  Box,
  Typography,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Link from "next/link";

interface CheckBoxesProps {
  register: UseFormRegister<yup.InferType<typeof schema>>;
  error?: FieldError | FieldError[];
}

const CheckBoxes = ({ register, error }: CheckBoxesProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...register("customEmiteoAlertMailAccepted")}
          color="primary"
          size="small"
        />
      }
      label={
        <Box>
          <Typography
            variant="body4c"
            mb={0.5}
            sx={{
              color: error ? "error.main" : "text.primary",
            }}
          >
            Zapoznałem się i akceptuję regulamin newslettera Emiteo.
          </Typography>
          <Typography variant="body4a">
            Administratorem Państwa danych osobowych jest Emiteo sp. z o.o. z
            siedzibą w {emiteoAddress} Państwa dane osobowe przetwarzane są w
            celu wysyłki newslettera. Szczegółowe postanowienia dotyczące danych
            osobowych znajdują się w regulaminie newslettera - zapoznaj się z{" "}
            <Link
              href={route.newsletterRegualations}
              target="_blank"
              rel="noreferrer"
            >
              treścią regulaminu
            </Link>
          </Typography>
        </Box>
      }
    />
  );
};

type NewsletterTypeProps = {
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
};

export const NewsletterIndex: React.FunctionComponent<NewsletterTypeProps> = ({
  eventCategory,
  eventAction,
  eventLabel,
}) => {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box display={"flex"} flexDirection="column" gap={6}>
      <Box
        display="flex"
        position="relative"
        flexDirection={{ xs: "column-reverse", md: "row" }}
        gap={4}
        id="newsletter"
      >
        <Box>
          <Typography variant="h2" mb={1}>
            Zapisz się na alert inwestora,
          </Typography>
          <Typography variant={isMdUp ? "h2" : "h4"} fontWeight={300}>
            a nie ominie Cię nic ważnego!
          </Typography>
        </Box>
        <Box sx={{ alignSelf: "center", display: { xs: "block", md: "none" } }}>
          <Image
            alt="Koperta"
            width={122}
            height={182}
            src="/images/index/newsletter-envelop.svg"
          />
        </Box>
      </Box>
      <Box display="flex" gap={6}>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Image
            alt="Koperta"
            width={203}
            height={303}
            src="/images/index/newsletter-envelop.svg"
          />
        </Box>
        <Box width={{ md: "55%" }}>
          <SimpleNewsletterForm
            checkBoxesComponent={CheckBoxes}
            eventCategory={eventCategory}
            eventAction={eventAction}
            eventLabel={eventLabel}
          />
        </Box>
      </Box>
    </Box>
  );
};
