"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";
import {
  GetMainPageBanners,
  GetMainPageBannersResponseItem,
} from "@/app/lib/network/content/GetMainPageBanners";
import { PrimaryButton } from "@/app/lib/components/commons/Button/PrimaryButton/PrimaryButton";
import { Box, Typography } from "@mui/material";
import { theme } from "@/app/lib/theme/theme";
import { Loader } from "@/app/lib/components/commons/Loader";

export const BannerContainer: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<GetMainPageBannersResponseItem[]>();

  useEffect(() => {
    GetMainPageBanners().then((res) => {
      setData(res.data);
      setIsLoading(false);
    });
  }, []);

  const Banner = ({ banner }: { banner: GetMainPageBannersResponseItem }) => {
    const { href, imgSrc, title, shortTitle, content, buttonText } = banner;

    return (
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: "column", md: "row" }}
        gap={3}
      >
        <Box
          sx={{
            transform: { lg: "translateX(-5%)" },
          }}
        >
          <Image
            src={imgSrc}
            alt={title}
            width={0}
            height={0}
            sizes="100vw"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: theme.spacing(3),
            }}
          />
        </Box>
        <Box
          sx={{
            pr: { md: 5 },
          }}
        >
          <Typography
            variant="h6"
            mb={1}
            color="#DA2CA5"
            textTransform="uppercase"
          >
            {shortTitle}
          </Typography>

          <Typography variant="h2" sx={{ mb: 3 }}>
            {title}
          </Typography>
          <Typography variant="body2a" sx={{ mb: 3 }}>
            {content}
          </Typography>
          <PrimaryButton
            href={href}
            text={buttonText}
            eventCategory="Homepage"
            eventAction="Click Banner"
            eventLabel={title}
          />
        </Box>
      </Box>
    );
  };

  return isLoading ? (
    <Loader isFullScreen />
  ) : data && data.length > 0 ? (
    <Banner banner={data[0]!} />
  ) : (
    <></>
  );
};
