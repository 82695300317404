"use client";

import React from "react";

import Image from "next/image";
import Link from "next/link";
import {
  Box,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
} from "@mui/material";

const courseItems = [
  {
    img: "/images/index/course-wideo.jpg",
    title: "Czym jest crowdfunding inwestycyjny?",
    link: {
      text: "Zobacz wideo",
      href: "https://www.youtube.com/watch?v=9v1o3XukBaA",
    },
  },
  {
    img: "/images/index/course-puzzles.jpg",
    title: "Czym właściwie jest crowdfunding?",
    link: {
      text: "Przejdź do bloga",
      href: "https://emiteo.pl/post/czym-wlasciwie-jest-crowdfunding",
    },
  },
  {
    img: "/images/index/course-books.jpg",
    title: "Crowdfunding inwestycyjny - jego początki i historia",
    link: {
      text: "Przejdź do bloga",
      href: "https://emiteo.pl/post/crowdfunding-udzialowy-jego-poczatki-i-historia",
    },
  },
  {
    img: "/images/index/course-tower.jpg",
    title: "Baza emisji crowdfundingu inwestycyjnego w Polsce",
    link: {
      text: "Przejdź do kompendium",
      href: "https://emiteo.pl/polskie-crowdfundingowe-emisje-akcji",
    },
  },
];

const courseItems2 = [
  {
    title: "Podcast Biznesowy: merytoryka&nbsp;i&nbsp;rozrywka ",
    description:
      "W PB analizujemy aktualne trendy, badamy&nbsp;ich przyczyny oraz oceniamy konsekwencje strategicznych decyzji biznesowych ",
    link: {
      href: "https://www.youtube.com/@PodcastBiznesowy",
      img: "/images/index/course-cover.jpg",
    },
  },
  {
    title: "Dlaczego warto szukać inwestorów?",
    description:
      "Dywersyfikuj ryzyko! Nie tylko wtedy, gdy&nbsp;inwestujesz w czyjeś projekty, ale również prowadząc swoje własne biznesy",
    link: {
      href: "https://www.youtube.com/watch?v=0iPH8s4EJhw",
      img: "/images/index/course-cover2.jpg",
    },
  },
  {
    title: "Jak działamy i dokąd zmierzamy?",
    description:
      "Dowiedz się, dlaczego jako jedyna platforma w Polsce otrzymaliśmy licencję&nbsp;i&nbsp;jak dzięki niej odbudowujemy polski crowdfunding",
    link: {
      href: "https://www.youtube.com/watch?v=KB7vbWbUqdw",
      img: "/images/index/course-cover3.jpg",
    },
  },
];

export const Course: React.FC = () => {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box display={"flex"} flexDirection="column" gap={6}>
      <Box>
        <Typography variant="h2" mb={1}>
          Chcesz wiedzieć więcej?
        </Typography>
        <Typography variant={isMdUp ? "h2" : "h4"} fontWeight={300}>
          Zacznij od naszego minikursu
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection={{ xs: "column", md: "row" }}
        gap={3}
      >
        {courseItems.map((item, index) => (
          <Card
            key={index}
            variant="outlined"
            sx={{ flex: 1, background: "white" }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Image
                  src={item.img}
                  alt={item.title}
                  width={101}
                  height={95}
                />
                <Typography variant="body2a" textAlign="center" mt={2}>
                  {item.title}
                </Typography>
              </Box>
              <Link href={item.link.href} target="_blank">
                {item.link.text}
              </Link>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        gap={{ xs: 3, lg: 6 }}
      >
        {courseItems2.map((item, index) => (
          <Box
            key={index}
            flex={1}
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="space-between"
          >
            <Box>
              <Typography
                variant="body1c"
                pb={1}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <Typography
                variant="body2a"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </Box>
            <Link href={item.link.href} target="_blank">
              <Box
                sx={{
                  position: "relative",
                  maxWidth: { xs: "333px", lg: "100%" },
                  width: "100%",
                  height: "187px",
                }}
              >
                <Image
                  src={item.link.img}
                  alt={item.title}
                  fill
                  loading="lazy"
                  style={{ borderRadius: 8, objectFit: "cover" }}
                />
              </Box>
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
