"use client";

import React from "react";
import Image from "next/image";
import { Box, Button, Container, Typography } from "@mui/material";
import Link from "next/link";

export const Discord: React.FC = () => {
  return (
    <Container maxWidth="sm">
      <Box display={"flex"} flexDirection="column" gap={4}>
        <Box
          display={"flex"}
          gap={2}
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
        >
          <Box>
            <Image
              alt="logo Discord"
              width={128}
              height={147}
              src="/images/index/discord-logo.jpg"
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="h4">
              Dołącz do Emiteo na&nbsp;Discordzie
            </Typography>
            <Typography variant="body2a">
              Interesuje Cię crowdfunding, krypto, a&nbsp;może&nbsp;po prostu
              dobre książki? Pogadajmy!
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent="center">
          <Link
            href="https://discord.gg/2p7gcZ3dJV"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="outlined" size="large">
              Dołącz
            </Button>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};
