"use client";

import { route } from "@/app/lib/utils/routes";
import Image from "next/image";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Link from "next/link";

const SearchInvestor: React.FunctionComponent = () => {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box display={"flex"} flexDirection="column" gap={6}>
      <Box>
        <Typography variant="h2" mb={1}>
          Szukam inwestora dla swojego biznesu…
        </Typography>
        <Typography variant={isMdUp ? "h2" : "h4"} fontWeight={300}>
          Dobrze trafiłeś!
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2a">
          W Emiteo pomagamy spółkom pozyskać kapitał poprzez crowdfunding
          inwestycyjny.
        </Typography>
        <Typography variant="body2a">
          Skontaktuj się z nami, a sprawdzimy, czy to odpowiednie rozwiązanie
          dla Twojego biznesu.
        </Typography>
      </Box>
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Card variant="outlined">
          <CardContent>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              gap={2}
            >
              <Image
                width="94"
                height="130"
                src="/images/getfinancing2/like-icon.svg"
                alt="like-icon"
              />
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="body2c">Jeżeli tak:</Typography>
                <Typography variant="body2a">
                  zaoferujemy Ci zorganizowanie emisji akcji Twojej spółki,
                  podczas której będziemy Cię wspierać od&nbsp;początku
                  do&nbsp;końca.
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Card variant="outlined">
          <CardContent>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              gap={2}
            >
              <Image
                width="94"
                height="130"
                src="/images/getfinancing2/question-icon.svg"
                alt="question-icon"
              />
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="body2c">Jeżeli nie:</Typography>
                <Typography variant="body2a">
                  wskażemy Ci alternatywne sposoby finansowania (jak np. venture
                  capital, aniołowie biznesu czy finansowanie dłużne).
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box display="flex" justifyContent="center">
        <Link href={route.funding}>
          <Button variant="contained">Umów się na konsultację</Button>
        </Link>
      </Box>
    </Box>
  );
};

export default SearchInvestor;
