import React from "react";
import Link from "next/link";
import { UrlObject } from "url";
import { color, fontWeight } from "@/app/lib/styles";
import { sendGAEvent } from "@next/third-parties/google";

type Props = {
  text: string;
  href: string | UrlObject;
  as?: string;
  isBack?: boolean;
  target?: string;
  outside?: boolean;
  noUppercase?: boolean;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
};

export const PrimaryButton: React.FunctionComponent<Props> = ({
  text,
  href,
  outside,
  target = "_blank",
  isBack,
  noUppercase,
  eventCategory,
  eventAction,
  eventLabel,
  as,
}) => {
  const renderLink = () => {
    if (outside) {
      return (
        <a
          className={` ${isBack ? "back" : ""} ${
            noUppercase ? "nouppercase" : ""
          }`}
          href={href.toString()}
          target={target}
        >
          {text}
        </a>
      );
    }
    return (
      <Link href={href} as={as}>
        <span
          className={` ${isBack ? "back" : ""} ${
            noUppercase ? "nouppercase" : ""
          }`}
          onClick={() =>
            sendGAEvent("event", "buttonClicked", {
              value: `${eventCategory} | ${eventAction} | ${eventLabel}`,
            })
          }
        >
          {text}
        </span>
      </Link>
    );
  };
  return (
    <div className="wrapper__button">
      {renderLink()}
      <style jsx global>
        {`
          .wrapper__button {
            display: flex;
          }
          .wrapper__button .back {
            background: ${color.white};
            border: 1px solid #5204f6;
            color: #4200ff;
            box-shadow: 0 2px 24px 0 rgba(66, 0, 255, 0.25);
          }
          .wrapper__button a {
            color: ${color.white};
            text-transform: uppercase;
            margin: 0;
            font-size: 0.875rem;
            font-weight: ${fontWeight.bold};
            letter-spacing: 0.39px;
            border: none;
            cursor: pointer;
            padding: 14px 54px;
            line-height: 18px;
            text-align: center;
            border-radius: 24px;
            background: linear-gradient(321.93deg, #ff359c 0%, #4200ff 100%);
            box-shadow: 0 2px 24px 0 rgba(66, 0, 255, 0.25);
            text-decoration: none;
          }
          .wrapper__button a.nouppercase {
            text-transform: none;
          }
        `}
      </style>
    </div>
  );
};
