"use client";

import { useEffect, useRef, useState } from "react";
import { IssuesList } from "@/app/lib/components/index-page/issues/IssuesList";
import { Loader } from "@/app/lib/components/commons/Loader";
import { getCollectedMoney } from "@/app/lib/domain/issue/Issue";
import {
  GetIssues,
  GetIssuesResponseItem,
} from "@/app/lib/network/issue/GetIssues";
import { Box, Typography, useMediaQuery } from "@mui/material";

type RenderIssuesListProps = {
  id: string;
  issues: GetIssuesResponseItem[];
  text: string;
};

const RenderIssuesList = ({ id, issues = [], text }: RenderIssuesListProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  useEffect(() => {
    if (
      0 < issues.length &&
      "undefined" !== typeof window &&
      window.location.hash &&
      "#" + id === window.location.hash
    ) {
      setTimeout(() => {
        window.scrollTo({
          top: (ref.current?.getBoundingClientRect().top ?? 0) - 50,
        });
      }, 1000);
    }
  }, [id, issues]);

  return (
    0 < issues.length && (
      <Box ref={ref} id={id}>
        <Typography variant={isMdUp ? "h2" : "h4"} mb={4}>
          {text}
        </Typography>
        <IssuesList issues={issues} />
      </Box>
    )
  );
};

export const IssuesContainer: React.FunctionComponent = () => {
  const [isLoadingActive, setIsLoadingActive] = useState(true);
  const [isLoadingSoon, setIsLoadingSoon] = useState(true);
  const [isLoadingEnd, setIsLoadingEnd] = useState(true);
  const [issuesActive, setIssuesActive] = useState<GetIssuesResponseItem[]>([]);
  const [issuesSoon, setIssuesSoon] = useState<GetIssuesResponseItem[]>([]);
  const [issuesEnd, setIssuesEnd] = useState<GetIssuesResponseItem[]>([]);

  useEffect(() => {
    GetIssues({ status: "ACTIVE" }).then((res) => {
      setIssuesActive(res.data);
      setIsLoadingActive(false);
    });

    GetIssues({ status: "SOON" }).then((res) => {
      setIssuesSoon(res.data);
      setIsLoadingSoon(false);
    });

    GetIssues({ status: "END" }).then((res) => {
      setIssuesEnd(
        res.data.sort(
          (a: GetIssuesResponseItem, b: GetIssuesResponseItem) =>
            getCollectedMoney(b) - getCollectedMoney(a),
        ),
      );
      setIsLoadingEnd(false);
    });
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={{ xs: 6, md: 8 }}
      id="issues"
    >
      {isLoadingActive ? (
        <Loader isFullScreen />
      ) : (
        <RenderIssuesList
          id="issues-active"
          text="Aktualne emisje akcji"
          issues={issuesActive}
        />
      )}
      {isLoadingSoon ? (
        <Loader isFullScreen />
      ) : (
        <RenderIssuesList
          id="issues-soon"
          text="Nadchodzące emisje"
          issues={issuesSoon}
        />
      )}
      {isLoadingEnd ? (
        <Loader isFullScreen />
      ) : (
        <RenderIssuesList
          id="issues-end"
          text="Zebrali ponad milion"
          issues={issuesEnd}
        />
      )}
    </Box>
  );
};
