import { AxiosPromise } from "axios";
import restApi from "@/app/lib/services/restApi";

export interface GetMainPageBannersResponseItem {
  imgSrc: string;
  href: string;
  shortTitle: string;
  content: string;
  buttonText: string;
  title: string;
}
export const GetMainPageBanners = (): AxiosPromise<
  GetMainPageBannersResponseItem[]
> => restApi.get("/api/v1/contents/mainPageBanners");
