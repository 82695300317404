"use client";

import Image from "next/image";
import { route } from "@/app/lib/utils/routes";
import "react-alice-carousel/lib/alice-carousel.css";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import Link from "next/link";

export const Ecf: React.FunctionComponent = () => {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box display={"flex"} flexDirection="column" gap={6}>
      <Box
        display="flex"
        position="relative"
        flexDirection={{ xs: "column-reverse", lg: "row" }}
        gap={4}
      >
        <Box flexShrink={1}>
          <Typography variant="h2" mb={1}>
            Opracowaliśmy największą w Internecie
          </Typography>
          <Typography variant={isMdUp ? "h2" : "h4"} fontWeight={300}>
            bazę polskiego crowdfundingu inwestycyjnego
          </Typography>
        </Box>
        <Box sx={{ display: { lg: "none", xs: "block" }, alignSelf: "center" }}>
          <Image
            alt="Laptop"
            width={203}
            height={192}
            src="/images/index/computer.png"
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          width={{ xs: "100%", lg: "70%" }}
        >
          <Typography variant="body2a">
            Dotarliśmy do informacji o emisjach prawie 200 spółek,
            które&nbsp;na&nbsp;przestrzeni ostatnich 10 lat skorzystały
            z&nbsp;crowdfundingu inwestycyjnego.
          </Typography>
          <Typography variant="body2a">
            Które firmy pozyskały w tym czasie najwięcej pieniędzy
            i&nbsp;w&nbsp;trakcie ilu emisji?
          </Typography>
          <Typography variant="body2a">
            Tego i nie tylko dowiesz się z naszego kompendium.
          </Typography>
        </Box>
        <Box sx={{ display: { lg: "block", xs: "none" } }}>
          <Image
            alt="Laptop"
            width={190}
            height={200}
            src="/images/index/computer.png"
          />
        </Box>
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Link href={route.ecf}>
          <Button variant="contained">Przechodzę do kompendium</Button>
        </Link>
      </Box>
    </Box>
  );
};
