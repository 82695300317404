"use client";

import React from "react";
import Image from "next/image";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Symbol } from "@/app/lib/theme/Symbol/Symbol";

export const WhatNext: React.FC = () => {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Box display={"flex"} flexDirection="column" gap={6}>
      <Box
        display="flex"
        position="relative"
        flexDirection={{ xs: "column-reverse", lg: "row" }}
        gap={4}
      >
        <Box>
          <Typography variant="h2" mb={1}>
            Kupiłem akcje
          </Typography>
          <Typography variant={isMdUp ? "h2" : "h4"} fontWeight={300}>
            zostałem inwestorem co&nbsp;dalej?
          </Typography>
        </Box>
        <Box
          sx={{
            position: { lg: "absolute" },
            top: -50,
            alignSelf: "center",
            right: 200,
            zIndex: -1,
          }}
        >
          <Image
            alt="Rakieta"
            width={190}
            height={197}
            src="/images/index/rocket.jpg"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems="end"
        gap={3}
      >
        <Box flex={1}>
          <Card variant="outlined" sx={{ mb: 3 }}>
            <CardContent>
              <Box display="flex" gap={1} alignSelf="center" pb={1}>
                <Symbol name="handshake" size="34px" />
                <Typography variant="body1c">Sprzedaż własna</Typography>
              </Box>
              <Typography variant="body2a">
                Możesz sprzedać swoje akcje bezpośrednio, poza obrotem
                giełdowym, w ramach umowy cywilnoprawnej.
              </Typography>
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <Box display="flex" gap={1} alignSelf="center" pb={1}>
                <Symbol name="keep" size="34px" />
                <Typography variant="body1c">Tablica ofert</Typography>
              </Box>
              <Typography variant="body2a">
                Zezwolenie, które otrzymaliśmy odKomisji Nadzoru Finansowego,
                pozwoliło nam zaprojektować i uruchomić własną tablicę ofert.
                Dzięki tej funkcjonalności osoby chętne sprzedać swoje akcje
                mogą szukać potencjalnych kupujących i vice versa. Tablicę ofert
                Emiteo uruchomiliśmy w sierpniu 2024 roku, a przedmiotem
                pojawiających się na niej ofert mogą być akcje emitowane przy
                współpracy z naszą platformą po 27 października 2023 roku.
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box flex={1}>
          <Card variant="outlined" sx={{ mb: 3 }}>
            <CardContent>
              <Box display="flex" gap={1} alignSelf="center" pb={1}>
                <Symbol name="campaign" size="34px" />
                <Typography variant="body1c">
                  Wezwanie do wykupu akcji
                </Typography>
              </Box>
              <Typography variant="body2a">
                Niektóre spółki mogą po pewnym czasie odkupić swoje akcje od
                akcjonariuszy po atrakcyjnej wycenie. Czasami spółki zapowiadają
                to już na etapie emisji, określając także stopę zwrotu.
              </Typography>
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <Box display="flex" gap={1} alignSelf="center" pb={1}>
                <Symbol name="ssid_chart" size="34px" />
                <Typography variant="body1c">Debiut na NewConnect</Typography>
              </Box>
              <Typography variant="body2a">
                Niektóre spółki mogą w przyszłości debiutować na rynku
                regulowanym lub w alternatywnym systemie obrotu ASO NewConnect.
                Ich akcje możesz wtedy sprzedać w obrocie giełdowym.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};
