import(/* webpackMode: "eager", webpackExports: ["BannerContainer"] */ "/opt/atlassian/pipelines/agent/build/app/lib/components/index-page/banner/BannerContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactContainer"] */ "/opt/atlassian/pipelines/agent/build/app/lib/components/index-page/contact/ContactContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Course"] */ "/opt/atlassian/pipelines/agent/build/app/lib/components/index-page/course/Course.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Discord"] */ "/opt/atlassian/pipelines/agent/build/app/lib/components/index-page/discord/Discord.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Ecf"] */ "/opt/atlassian/pipelines/agent/build/app/lib/components/index-page/ecf/Ecf.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IssuesContainer"] */ "/opt/atlassian/pipelines/agent/build/app/lib/components/index-page/issues/IssuesContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterIndex"] */ "/opt/atlassian/pipelines/agent/build/app/lib/components/index-page/newsletter/Newsletter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Platform"] */ "/opt/atlassian/pipelines/agent/build/app/lib/components/index-page/platform/Platform.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/app/lib/components/index-page/searchInvestor/searchInvestor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WhatNext"] */ "/opt/atlassian/pipelines/agent/build/app/lib/components/index-page/whatNext/WhatNext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContainerMain"] */ "/opt/atlassian/pipelines/agent/build/app/lib/components/layouts/Container/ContainerMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterMain"] */ "/opt/atlassian/pipelines/agent/build/app/lib/components/layouts/Footer/FooterMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderMain"] */ "/opt/atlassian/pipelines/agent/build/app/lib/components/layouts/Header/HeaderMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/node_modules/@mui/material/Container/Container.js");
